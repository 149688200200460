<template>
  <div class="test1">
    <el-header class="header">
      <el-row class="edit-header">
        <el-col :span="4" class="lf-bt">
          <el-popover width="0" trigger="hover">
            <el-button
              slot="reference"
              icon="el-icon-edit-outline"
              @click="open_edit"
              >Edit Model Name</el-button
            >
          </el-popover>
        </el-col>
        <el-col :span="4" class="button"
          ><el-button type="warning" icon="el-icon-receiving" @click="save"
            >Save</el-button
          ></el-col
        >
        <el-col :span="2"></el-col>
      </el-row>
    </el-header>

    <el-container class="container">
      <el-aside class="side">
        <el-tabs v-model="activeName" @tab-click="InitOutline">
          <el-tab-pane label="Type" name="first">
            <div class="edit">
              <div class="ques">
                <div class="edit-ques">
                  <i class="el-icon-check"></i>
                  <span> Model Input&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="qsEditDialogVisible = true"
                  ></i>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-aside>

      <el-main class="main">
        <div class="ques">
          <div class="title">Model Name: {{ model_name }}</div>
          <div class="content">
            <el-row
              class="ques-block"
              v-for="item in questions"
              :key="item.num"
              @mouseover.native="hoverItem = item.id"
            >
              <el-col :span="16" class="block-content">
                <div class="block-title">
                  {{ item.LR_variable_name }}
                </div>
                <div class="block-description">Type: {{ item.type }}</div>
                <div class="block-description">
                  ML Name: {{ item.ML_variable_name }}
                </div>
                <div
                  class="block-choice"
                  v-for="ans in item.conditions"
                  :key="ans.id"
                >
                  If {{ ans.condition }}, {{ item.LR_variable_name }} =
                  {{ ans.value }}
                </div>
              </el-col>

              <el-col
                :span="8"
                class="block-button"
                style="text-align: right"
                v-if="hoverItem === item.id"
              >
                <el-button-group>
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Edit"
                    placement="bottom"
                  >
                    <el-button
                      class="bt"
                      type="primary"
                      icon="el-icon-edit"
                      @click="edit(item.id)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Delete"
                    placement="bottom"
                  >
                    <el-button
                      class="bt"
                      type="primary"
                      icon="el-icon-delete"
                      @click="del_pre(item.id)"
                    ></el-button>
                  </el-tooltip>
                </el-button-group>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-main>
    </el-container>

    <el-dialog
      :title="qsEditDialogTitle"
      :visible.sync="qsEditDialogVisible"
      :before-close="cancel_pre"
      class="dialog"
    >
      <el-form ref="form" :model="willAddQuestion" label-width="100px">
        <div class="not-info">
          <el-form-item label="LR model variable name" style="width: 90%;">
            <el-input
              v-model="willAddQuestion.LR_variable_name"
              placeholder="LR model variable name"
            ></el-input>
          </el-form-item>

          <el-form-item label="Type">
            <el-select
              v-model="willAddQuestion.type"
              placeholder="Choose data type"
              @change="typeChange"
            >
              <el-option
                v-for="item in allExportType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <template>
            <el-form-item
              :label="'condition' + (index + 1)"
              v-for="(item, index) in willAddQuestion.conditions"
              :key="index"
            >
              <el-row>
                <el-col :span="1">
                  <p style="line-height: 13px;margin-left:10px">If</p>
                </el-col>
                <el-col :span="4">
                  <el-input
                    v-model="item.condition"
                    placeholder="Condition"
                  ></el-input>
                </el-col>
                <el-col :span="1">
                  <p style="line-height: 13px;margin-left:10px">,</p>
                </el-col>
                <el-col :span="4">
                  <el-input
                    :disabled="true"
                    v-model="willAddQuestion.LR_variable_name"
                    placeholder="Name"
                  ></el-input>
                </el-col>
                <el-col :span="1">
                  <p style="line-height: 13px;margin-left:10px">=</p>
                </el-col>
                <el-col :span="4">
                  <el-input v-model="item.value" placeholder="Value"></el-input>
                </el-col>
                <el-col :span="8">
                  <el-button
                    type="danger"
                    plain
                    class="deleteOptionButton"
                    @click="deleteOption(index)"
                    >Delete Option</el-button
                  >
                </el-col>
              </el-row>
            </el-form-item>
            <el-button
              type="primary"
              plain
              class="addOptionButton"
              @click="addOption"
              >New Option</el-button
            >
          </template>

          <el-form-item label="ML model variable name" style="width: 90%;">
            <el-input
              v-model="willAddQuestion.ML_variable_name"
              placeholder="ML model variable name"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-row>
          <el-button :span="6" type="primary" @click="dialogConfirm"
            >Confirm</el-button
          >
          &emsp;
          <el-button :span="6" @click="cancel_pre">Cancel</el-button>
        </el-row>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="editWrongMsgVisible"
      class="linkDialog"
      :show-close="false"
      :close-on-click-modal="false"
      width="300px"
    >
      <span>{{ editWrongMsg }}</span>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-row>
          <el-button
            :span="6"
            type="danger"
            style="width: 80px"
            @click="editWrongMsgVisible = false"
            >知道了</el-button
          >
        </el-row>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import getDataApi from "@/utils/getDataApi";
import saveDataApi from "@/utils/saveDataApi";

export default {
  name: "RightScreen",
  mixins: [getDataApi, saveDataApi],
  data() {
    return {
      model_name: "Please add Model Name",
      allExportType: [
        {
          name: "float",
          value: "float",
        },
        {
          name: "int",
          value: "int",
        },
      ],
      editWrongMsg: "",
      editWrongMsgVisible: false,
      editIndex: 0,
      selectDisAble: false,
      hoverItem: 0,
      activeName: "first",
      title: "Please add questionnaire name",
      description: "",
      industry: "Please add questionnaire industry ID",
      company: "Please add questionnaire company type ID",
      questions: [],
      qid: this.$route.query.qid,
      qsEditDialogVisible: false,
      qsEditDialogTitle: "New Model Input",
      willAddQuestion: {
        question_id: 0,
        id: 0,
        LR_variable_name: "",
        conditions: [
          {
            condition: "true",
            value: "",
          },
        ],
        coefficient: "",
        coefficient2: "",
        area: "",
        ML_variable_name: "",
        type: "float",
        value: "",
      },
    };
  },
  components: {
    //   editHeader,
  },
  methods: {
    finish() {
      this.qsLinkDialogVisible = false;
      this.$router.push("/index");
    },

    edit: function(index) {
      this.willAddQuestion = {
        question_id: this.questions[index].question_id,
        id: this.questions[index].id,
        LR_variable_name: this.questions[index].LR_variable_name,
        conditions: JSON.parse(
          JSON.stringify(this.questions[index].conditions)
        ),
        coefficient: this.questions[index].coefficient,
        coefficient2: this.questions[index].coefficient2,
        area: this.questions[index].area,
        ML_variable_name: this.questions[index].ML_variable_name,
        type: this.questions[index].type,
        value: this.questions[index].value,
      };
      this.editIndex = index;
      this.selectDisAble = true;
      this.qsEditDialogTitle = "Edit Model Input";
      this.qsEditDialogVisible = true;
    },
    isExistEmptyOption: function() {
      for (let i = 0; i < this.willAddQuestion.conditions.length; i++) {
        if (this.willAddQuestion.conditions[i].condition == "") return true;
        if (this.willAddQuestion.conditions[i].value == "") return true;
      }
      return false;
    },
    isExistSameOption: function() {
      return false;
    },
    isValidVariableName: function(variableName) {
      return /^[a-zA-Z_]\w*$/.test(variableName);
    },
    dialogConfirm() {
      let index = this.editIndex;
      // this.qsEditDialogVisible = false;
      if (this.qsEditDialogTitle === "Edit Model Input") {
        if (!this.isValidVariableName(this.willAddQuestion.LR_variable_name)) {
          this.$message({
            type: "error",
            message: "LR_variable_name is not in a valid format!",
          });
          return;
        }
        // if (this.willAddQuestion.LR_variable_name === '') {
        //     this.$message({
        //         type: 'error',
        //         message: 'LR Name cannot be empty!'
        //     });
        //     return;
        // }
        if (this.willAddQuestion.ML_variable_name === "") {
          this.$message({
            type: "error",
            message: "ML Name cannot be empty!",
          });
          return;
        }
        if (this.isExistEmptyOption()) {
          this.$message({
            type: "error",
            message: "Option cannot be empty!",
          });
          return;
        } else {
          this.questions[index].id = this.willAddQuestion.id;
          this.questions[index].question_id = this.willAddQuestion.question_id;
          this.questions[
            index
          ].LR_variable_name = this.willAddQuestion.LR_variable_name;
          this.questions[index].conditions = this.willAddQuestion.conditions;
          this.questions[index].coefficient = this.willAddQuestion.coefficient;
          this.questions[
            index
          ].coefficient2 = this.willAddQuestion.coefficient2;
          this.questions[index].area = this.willAddQuestion.area;
          this.questions[
            index
          ].ML_variable_name = this.willAddQuestion.ML_variable_name;
          this.questions[index].type = this.willAddQuestion.type;
          this.questions[index].value = this.willAddQuestion.value;
          this.qsEditDialogTitle = "";
          this.qsEditDialogVisible = false;
          this.$message({
            type: "success",
            message: "Successful!",
          });
          this.resetWillAdd();
          this.selectDisAble = false;
        }
        this.resetWillAdd();
        this.selectDisAble = false;
      } else {
        if (!this.isValidVariableName(this.willAddQuestion.LR_variable_name)) {
          this.$message({
            type: "error",
            message: "LR_variable_name is not in a valid format!",
          });
          return;
        }
        // if (this.willAddQuestion.LR_variable_name === '') {
        //     this.$message({
        //         type: 'error',
        //         message: 'LR Name cannot be empty!'
        //     });
        //     return;
        // }
        if (this.willAddQuestion.ML_variable_name === "") {
          this.$message({
            type: "error",
            message: "ML Name cannot be empty!",
          });
          return;
        }
        if (this.isExistEmptyOption()) {
          this.$message({
            type: "error",
            message: "Option cannot be empty!",
          });
          return;
        } else {
          this.qsEditDialogVisible = false;
          this.willAddQuestion.id = this.questions.length;
          this.questions.push(this.willAddQuestion);
          this.$message({
            type: "success",
            message: "Succesfully added!",
          });
          this.resetWillAdd();
        }
      }
    },
    del_pre: function(index) {
      this.$confirm(
        "Are you sure you want to delete this model input? (Cannot recover)",
        "",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.del(index);
          this.$message({
            type: "success",
            message: "Delete successfuly",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Cancel",
          });
        });
    },
    resetWillAdd() {
      this.willAddQuestion = {
        question_id: 0,
        id: 0,
        LR_variable_name: "",
        conditions: [
          {
            condition: "true",
            value: "",
          },
        ],
        coefficient: "",
        coefficient2: "",
        area: "",
        ML_variable_name: "",
        type: "float",
        value: "",
      };
    },
    dialogCancel: function() {
      this.qsEditDialogTitle = "New Model Input";
      this.resetWillAdd();
      this.qsEditDialogVisible = false;
      this.selectDisAble = false;
    },
    cancel_pre: function() {
      this.$confirm(
        "Info you have input will not be saved, are you sure you want to close this window?",
        "提示",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.dialogCancel();
        })
        .catch(() => {});
    },
    typeChange(value) {
      this.willAddQuestion.type = value;
    },
    radioChange(value) {
      this.willAddQuestion.refer = value;
    },
    judgeChange(value) {
      this.willAddQuestion.refer = value;
    },
    addOption() {
      this.willAddQuestion.conditions.push({
        condition: "",
        value: "",
      });
    },
    deleteOption(index) {
      if (this.willAddQuestion.conditions.length === 1) {
        this.$message({
          type: "error",
          message: "Must have a value!",
        });
      } else {
        this.willAddQuestion.conditions.splice(index, 1);
      }
    },
    changeModelName: function(value) {
      this.model_name = value;
    },
    open_edit: function() {
      this.$prompt("Please input Model Name", "Model Name", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      })
        .then(({ value }) => {
          this.changeModelName(value);
          this.$message({
            type: "success",
            message: "Model Name has been edited",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Cancelled",
          });
        });
    },
    openSetting: function() {
      this.settingDialogVisible = true;
    },
    settingSuccess: function() {
      this.settingDialogVisible = false;
      this.$message({
        type: "success",
        message: "Successful",
      });
    },

    saveinfo(tag) {
      this.saveQnInfo(tag, "2");
    },
    save() {
      if (this.model_name == "Please add Model Name") {
        this.$message({
          type: "error",
          message: "Please set a model name!",
        });
        return;
      }
      if (this.qid != undefined) {
        window.localStorage.setItem(`model_name${this.qid}`, this.model_name);
        window.localStorage.setItem(
          `linear_model${this.qid}`,
          JSON.stringify(this.questions)
        );
      } else {
        window.localStorage.setItem("model_name", this.model_name);
        window.localStorage.setItem(
          "linear_model",
          JSON.stringify(this.questions)
        );
      }
      this.$message.success("Model Inputs Saved!");
    },

    del: function(index) {
      let questions = this.questions;
      for (let i = index + 1; i < questions.length; i++) {
        questions[i].id--;
      }
      questions.splice(index, 1);
    },

    updateQuestions: function(start, end) {
      let offset = end - start;
      if (offset > 0) {
        for (let i = 0; i < offset; i++) {
          this.down(start + i);
        }
      } else {
        for (let i = 0; i < offset * -1; i++) {
          this.up(start - i);
        }
      }
    },

    InitOutline() {
      for (var i = 0; i < this.questions.length; i++) {
        this.outline.push({
          id: this.questions[i].id,
          label: i + 1 + ". " + this.questions[i].title,
        });
      }
    },
  },
  computed: {},
  created() {
    if (this.qid != undefined) {
      if (window.localStorage.getItem(`model_name${this.qid}`) != undefined) {
        this.model_name = window.localStorage.getItem(`model_name${this.qid}`);
        this.questions = JSON.parse(
          window.localStorage.getItem(`linear_model${this.qid}`)
        );
      } else {
        this.$axios({
          method: "get",
          url:
            "https://api.oxvalue.ai/api/question/v0.1/questionnaire_admin/" +
            this.qid +
            "/",
          headers: {
            Authorization: window.localStorage.getItem("ova_admin_token"),
          },
        }).then((res) => {
          var model_id = res.data.data.fk_plugin_id;
          this.$axios({
            method: "get",
            url: `https://api.oxvalue.ai/api/plugin/v0.1/plugin/${model_id}/`,
            headers: {
              Authorization: window.localStorage.getItem("ova_admin_token"),
            },
          }).then((res2) => {
            console.log(res2.data);
            if (res2.data.data.name != undefined) {
              this.model_name = res2.data.data.name;
              this.questions = JSON.parse(res2.data.data.linear_model);
            }
            for (let i = 0; i < this.questions.length; i++) {
              this.questions[i]["id"] = i;
            }
          });
        });
      }
    } else {
      if (window.localStorage.getItem("model_name") != undefined) {
        this.model_name = window.localStorage.getItem("model_name");
      }
      if (window.localStorage.getItem("linear_model") != undefined) {
        this.questions = JSON.parse(
          window.localStorage.getItem("linear_model")
        );
      }
      for (let i = 0; i < this.questions.length; i++) {
        this.questions[i]["id"] = i;
      }
    }
  },
  mounted() {
    // this.timer = setInterval(this.autoSave, 60000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style>
.test1 .linkDialog {
  text-align: left;
}
.test1 .container {
  padding: 0;
  height: auto;
  min-height: 610px;
}

.test1 header {
  padding: 0 10px;
}

.test1 .el-container {
  padding: 0 5px;
}

.test1 .side {
  border-top: solid 1px #e3e3e3;
  border-right: solid 1px #e3e3e3;
  background: #ffffff;
}

.test1 .main {
  border-top: solid 1px #e3e3e3;
  background: #ffffff;
}

.test1 .edit {
  margin-top: 0;
  overflow: auto;
  height: 550px;
}

.test1 .outline {
  overflow: auto;
  height: 550px;
}

.test1 .ques-type {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test1 .edit-title {
  color: black;
  padding: 15px 0;
  font-size: 16px;
  border-bottom: solid #e3e3e3 2px;
}

.test1 .edit-ques {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test1 .type-icon {
  color: #1687fd;
  display: inline-block;
}

.test1 .type-icon:hover {
  color: #409eff;
  cursor: pointer;
}

.test1 .el-tabs__nav-scroll {
  text-align: center;
  height: 60px;
  margin: 0 60px;
}

.test1 .el-tabs__item {
  font-weight: bold;
  padding: 0 20px;
  height: 60px;
  box-sizing: border-box;
  line-height: 60px;
  display: inline-block;
  list-style: none;
  font-size: 16px;
  color: black;
  position: relative;
}

.test1 .el-tabs__header {
  margin: 0;
}

.test1 .el-tree-node__content {
  padding-left: 10px !important;
  height: 40px;
}

.test1 .main {
  /* max-height: 610px; */
  max-height: calc(100vh - 140px);
}

.test1 .ques .title {
  font-size: 28px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 26px;
}

.test1 .ques .description {
  text-align: left;
  font-size: 16px;
  padding-bottom: 30px;
  color: black;
  line-height: 30px;
}

.test1 .ques-block {
  padding-bottom: 15px;
  border-top: solid #e3e3e3 1px;
}

.test1 .ques-block:hover {
  background: #f5f5f5;
  transition: 0.3s;
}

.test1 .ques-block .must {
  font-weight: normal;
  color: crimson;
}

.test1 .block-title {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 20px 10px 10px;
  font-weight: bold;
}

.test1 .block-description {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 10px;
  color: #969696;
}

.test1 .block-refer {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #51c215;
}

.test1 .block-point {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #e59824;
}

.test1 .block-choice {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 5px 10px 10px;
}

.test1 .el-button-group > .el-button:first-child {
  border-radius: 0 0 0 8px;
}

.test1 .el-button-group > .el-button:last-child {
  border-radius: 0 0 8px 0;
}

.test1 .block-button .el-button {
  background: #b9b9b9;
  border: transparent;
  padding: 12px 16px;
  font-size: 16px;
}

.test1 .block-button .el-button:hover {
  background: #989898;
  border: transparent;
}

.test1 .bt {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.test1 .block-choice .el-textarea__inner {
  max-height: 100px;
}

.test1 .dialog {
  text-align: left;
  font-size: large;
}
.test1 .addOptionButton {
  display: inline-block;
  margin-left: 100px;
}
.test1 .deleteOptionButton {
  margin-left: 20px;
}

.test1 .logic-description {
  margin-top: -15px;
  margin-bottom: 30px;
  text-align: left;
  padding-left: 6px;
  font-size: 14px;
  color: #aaaaaa;
}

.test1 .important {
  color: crimson;
}

.test1 .logic-title {
  font-size: 16px;
  font-weight: bold;
  padding-left: 12px;
  padding-bottom: 30px;
}

.test1 .logic-bt {
  text-align: center;
}

.test1 .addOptionButton {
  display: inline-block;
  margin-left: 100px;
  margin-bottom: 22px;
}

.test1 .deleteOptionButton {
  margin-left: 20px;
}

.test1 .settingDialog {
  text-align: center;
}

.test1 .settingDialog .el-dialog__body {
  padding-left: 30px;
}

.test1 .logic-form .el-input--suffix .el-input__inner {
  width: 520px;
}

.test1 .logic-info {
  color: #aaaaaa;
  padding-top: 2px;
  padding-left: 12px;
  padding-bottom: 25px;
}

.test1 .block-relation {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #1687fd;
}
</style>
